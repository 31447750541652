body {
  text-align: center;
  font-size: 1em;
  font-family: verdana;
  margin: 0;
}

.header {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  margin: 0 0 10px 0;
}

.header > h1 {
  font-size: 1.2em;
  color: #333;
  line-height: 40px;
  display: inline;
  text-align: center;
  margin-left: 40px;
}

.header > button.directions-btn {
  width: 40px;
  height: 40px;
  border: none;
  background-color: white;
  float: right;
  display: inline;
  cursor: pointer;
  color: black;
}

.row {
  text-align: center;
  display: flex;
  justify-content: center;
}
.row > div {
  display: block;
  width: 70px;
  height: 70px;
  border: 1px solid #bbb;
  margin: 4px;
  text-align: center;
  line-height: 70px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2.5em;
  cursor: pointer;
}

.row > div.white {
  background: white;
  color: black;
  border: 1px solid #bbb;
}

.row > div.green-not-animated {
  background: rgb(106, 170, 100);
  border-color: rgb(106, 170, 100);
  color: white;
}
.row > div.green {
  --background: rgb(106, 170, 100);
  border-color: rgb(106, 170, 100);
  animation: flip 0.5s ease forwards;
}
.row > div.grey {
  --background: rgb(120, 124, 126);
  border-color: rgb(120, 124, 126);
  animation: flip 0.5s ease forwards;
}
.row > div.yellow {
  --background: rgb(201, 180, 88);
  border-color: rgb(201, 180, 88);
  animation: flip 0.5s ease forwards;
}

.row > div.current {
  border: 3px solid !IMPORTANT;
  border-color: black !IMPORTANT;
  color: black;
  transform: scale(1.02);
}

.keypad {
  max-width: 500px;
  margin: 20px auto;
}

.keypad > div {
  margin: 5px;
  width: 30px;
  height: 40px;
  background: #eee;
  display: inline-block;
  border-radius: 6px;
  line-height: 40px;
  font-size: 13px;
  border: none;
  cursor: pointer;
}

.keypad > div.special {
  width: 70px;
}

.keypad > div.green {
  background: rgb(106, 170, 100);
  color: white;
  transition: all 0.3s ease-in;
  z-index: 0;
}
.keypad > div.yellow {
  background: rgb(201, 180, 88);
  color: white;
  transition: all 0.3s ease-in;
  z-index: 0;
}
.keypad > div.grey {
  background: rgb(120, 124, 126);;
  color: white;
  transition: all 0.3s ease-in;
  z-index: 0;
}

.row > div:nth-child(2) {
  animation-delay: 0.2s;
}
.row > div:nth-child(3) {
  animation-delay: 0.4s;
}
.row > div:nth-child(4) {
  animation-delay: 0.6s;
}
.row > div:nth-child(5) {
  animation-delay: 0.8s;
}
.row > div:nth-child(6) {
  animation-delay: 1.0s;
}
.row > div:nth-child(7) {
  animation-delay: 1.2s;
}
.row > div:nth-child(8) {
  animation-delay: 1.4s;
}

#directions-modal {
  display: none;
}

.modal {
  background: rgba(255,255,255,0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.modal div.outer {
  max-width: 480px;
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  margin: 3% auto;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
  justify-content: center;
}

.modal div.outer > div.row-solution {
  text-align: center;
  display: flex;
  justify-content: center;
}

.modal div.outer > div.row-solution > div {
  display: block;
  width: 35px;
  height: 35px;
  border: 1px solid #bbb;
  margin: 2px;
  padding: 0px;
  text-align: center;
  line-height: 35px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.25em;
  background: white;
  color: black;
}

.modal div.outer > div.share-box {
  text-align: center;
  display: flex;
  justify-content: center;
}

.modal div.outer > div.share-box > div {
  display: block; 
  cursor: pointer;
  line-height: 30px;
  height: 30px;
  width: 80px;
  text-align: center;
  border-radius: 8%;
  margin-top: 12px;
  padding: 5px;
  background-color: rgb(106, 170, 100);
  color: white;
}


@keyframes flip {
  0% {
    transform: rotateX(0);
    background: white;
  }
  45% {
    transform: rotateX(90deg);
    background: white;
  }
  55% {
    transform: rotateX(90deg);
    background: var(--background);
    color: white;
  }
  100% {
    transform: rotateX(0deg);
    background: var(--background);
    color: white;
  }
}

@keyframes pop {
  0% { 
    transform: scale(1);
  }
  50% { 
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.02);
  }
}
